import CustomInput from '@components/Input'
import './style.scss'
import CustomButton from '@components/CustomButton'
import { InputGroup } from 'rsuite'
import Close from '@img/close.svg'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const NoUPCPopup = ({
  onPriceChange,
  onChangeDesc,
  onChangeQty,
  headline,
  priceValue,
  descVal,
  cancelButtonAction,
  okButtonAction,
  quantity,
  showDescField,
  maxLimitText,
  editPrice,
  upc,
  priceDisabled,
  standardPriceButtonAction,
}: any) => {
  const [errors, setErrors] = useState<any>({})
  const { processPalletInfo } = useSelector((store: any) => store.pricing)
  const qntLimit = processPalletInfo.letter === null ? 30 : 1000

  const validateFields = () => {
    console.log('QNT LIMIT', qntLimit)

    if (priceValue === '') {
      setErrors({ priceValue: true })
    } else if (descVal === '' && showDescField) {
      setErrors({ descVal: true })
    } else if (parseInt(quantity) > qntLimit || parseInt(quantity) < 1) {
      setErrors({ quantity: true })
    }
  }
  return (
    <div className="loader-container">
      <div className="popup-layer">
        <div className="popup-header">
          <p className="popup-headline">{headline}</p>
          <InputGroup.Addon
            style={{ backgroundColor: 'transparent', border: '0px' }}
          >
            <img
              src={Close}
              className="input_icon"
              onClick={cancelButtonAction}
            />
          </InputGroup.Addon>
        </div>
        {editPrice && (
          <div className="upc-title-div">
            <label className="upc-title">UPC: {upc} </label>
          </div>
        )}
        <div>
          <label className="no-upc-price">Price *</label>
          <InputGroup
            inside
            style={{
              border: errors.priceValue ? '1px solid red' : '1px solid #5b5a5a',
              width: '264px',
              marginTop: '6px',
              borderRadius: '10px',
            }}
          >
            <InputGroup.Addon style={{ fontSize: 22, paddingTop: 13 }}>
              $
            </InputGroup.Addon>
            <CustomInput
              onChange={(e: any) => {
                onPriceChange(e)
                errors.priceValue && setErrors({ ...errors, priceValue: false })
              }}
              value={priceValue}
              disabled={priceDisabled}
              onKeyDown={(event: any) => {
                if (event.keyCode === 13 || event.key === 'Enter') {
                  okButtonAction()
                }
              }}
            />
          </InputGroup>
          {/* <p className="info-para">Max limit 30</p> */}
        </div>
        {showDescField && (
          <div className="form-fields">
            <label>Description</label>
            <div
              className={
                errors?.descVal ? 'upc-input field-error' : 'upc-input'
              }
            >
              <CustomInput
                onChange={(e: any) => {
                  onChangeDesc(e)
                  errors.descVal && setErrors({ ...errors, descVal: false })
                }}
                value={descVal}
                onKeyDown={(event: any) => {
                  if (event.keyCode === 13 || event.key === 'Enter') {
                    okButtonAction()
                  }
                }}
              />
            </div>
          </div>
        )}{' '}
        <div className="form-fields">
          <label className="form-labels">Label Quantity *</label>
          <div
            className={errors?.quantity ? 'upc-input field-error' : 'upc-input'}
          >
            <CustomInput
              onChange={(e: any) => {
                onChangeQty(e)
                errors.quantity && setErrors({ ...errors, quantity: false })
              }}
              type="number"
              value={quantity}
              onKeyDown={(event: any) => {
                if (event.keyCode === 13 || event.key === 'Enter') {
                  okButtonAction()
                }
              }}
            />
          </div>
          <p className="info-para">{maxLimitText ?? 'Max limit ' + qntLimit}</p>
        </div>
        <div
          className={
            'popup-' + (showDescField ? 'btns-wrapper' : 'btns-wrapper')
          }
        >
          {showDescField && ( // we are showing the description for print without upc and price edit functionality and we want to show the standard price btn for only print without upc and price edit
            <div className="popup-btn-stdprice">
              <CustomButton
                buttonText="Standard Price"
                width="140px"
                activeColor={'#D5D1D1'}
                activeTextColor="#24272C"
                onClick={() => {
                  standardPriceButtonAction()
                }}
                isActive
              />
            </div>
          )}
          <div className={'popup-' + (showDescField ? 'btn-ok' : 'others')}>
            <CustomButton
              buttonText="Ok"
              width="100px"
              activeColor={'#D5D1D1'}
              activeTextColor="#24272C"
              onClick={() => {
                validateFields()
                okButtonAction()
              }}
              isActive
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoUPCPopup
