import { useTranslation } from 'react-i18next'
import './style.scss'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import useClothingEnhancer from '@tabs/Clothing/useClothingEnhancer'
import moment from 'moment'
import momentTimezone from 'moment-timezone'

moment().tz('America/Los_Angeles').format()

export default function GeneralInfo({
  showProductiveUI,
  isDashboard,
  isButtonDisabled,
  setIsButtonDisabled,
}: any) {
  const { t } = useTranslation()
  const { productivityCount } = useSelector((store: any) => store.pricing)
  const { updateProductivityCount } = useClothingEnhancer()
  const [PRCountDay, setPRCountDay] = useState(0.0)
  const [PRCountPerHour, setPRCountDayPerHour] = useState(0.0)
  const { userProductivityInfo } = useSelector(
    (store: any) => store.persistedData
  )

  useEffect(() => {
    updateProdictivityWithCurrentTime()
  }, [productivityCount])

  useEffect(() => {
    const productivityIntervalForMinute = setInterval(() => {
      updateProductivityAPI()
    }, 60000) // Update data in every 1 minutes // 60000

    return () => {
      clearInterval(productivityIntervalForMinute)
    }
  }, [])

  const getBGColor = (isDay: boolean) => {
    if (
      (isDay
        ? productivityCount?.day_count || 0
        : productivityCount?.total_count || 0) <= 0
    ) {
      return '#666666' // gray
    } else if ((isDay ? PRCountDay : PRCountPerHour) > 95) {
      return '#39c769' // green
    } else if ((isDay ? PRCountDay : PRCountPerHour) > 89) {
      return '#FD822A' //  orange
    } else {
      return '#E32732'
    }
  }

  function updateProductivityAPI() {
    const utcAPITime = moment(userProductivityInfo?.user_logged_in_time)
    const cstCurrentTIme = momentTimezone().tz('America/Chicago')
    let minutesDifference = moment(
      cstCurrentTIme.format('YYYY-MM-DD HH:mm:ss')
    ).diff(moment(utcAPITime.format('YYYY-MM-DD HH:mm:ss')), 'minutes')

    if (userProductivityInfo?.total_duration) {
      minutesDifference =
        minutesDifference + userProductivityInfo?.total_duration
    }

    const min = minutesDifference % 60

    if (min === 0 || min === 1 || min === 59) {
      updateProductivityCount(0)
    }
  }

  function updateProdictivityWithCurrentTime() {
    const PRCountDayValue = Math.round(productivityCount?.productivity_for_day)
    setPRCountDay(PRCountDayValue)
    const PRCountPerHourValue = Math.round(productivityCount?.productivity)
    setPRCountDayPerHour(PRCountPerHourValue)
  }
  const getProductivity = () => {
    if (isButtonDisabled) {
      return
    }
    updateProductivityCount(0)
    // Disable the button
    setIsButtonDisabled(true)
    // Enable the button after 5 minutes  5 * 60 * 1000
    setTimeout(() => {
      setIsButtonDisabled(false)
    }, 5 * 60 * 1000)
  }
  return (
    <div>
      {showProductiveUI && (
        <div
          className={isDashboard ? 'app-footer-dasboard' : 'app-footer'}
          style={{
            justifyContent:
              isButtonDisabled && isDashboard ? 'space-around' : 'flex-end',
          }}
        >
          {!isDashboard && (
            <div className="general-info">
              {process.env.REACT_APP_ENV !== 'prod' && (
                <div className="info info1">
                  Env : {process.env.REACT_APP_ENV}
                </div>
              )}
              <div className="info info1">{t('station_id')} : FB-SWPL-28</div>
              <div className="info info2">
                {t('version')} : {process.env.REACT_APP_VERSION}
              </div>
            </div>
          )}{' '}
          {isButtonDisabled && (
            <div className="productivity-info-boxes">
              <div
                className="productivity-wrapper"
                style={{ backgroundColor: getBGColor(false) }}
              >
                <div className="productive-text">
                  Productivity : {PRCountPerHour || 0}%
                </div>
                <div className="productive-text">
                  Scan this Hrs : {productivityCount?.total_count || 0}
                </div>
              </div>
              <div
                className="productivity-wrapper"
                style={{ backgroundColor: getBGColor(true) }}
              >
                <div className="productive-text">
                  Productivity : {PRCountDay || 0}%
                </div>

                <div className="productive-text">
                  Scan this Day : {productivityCount?.day_count || 0}
                </div>
              </div>
            </div>
          )}{' '}
          <div
            className="getproductivity-info-button"
            onClick={getProductivity}
          >
            <div
              className="productivity-wrapper"
              style={{
                backgroundColor: isButtonDisabled ? '#666666' : '#000000',
              }}
            >
              <div className="productive-text">Productivity</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
